import { PageEvents } from "~/enums/tracking/tracking-events.enums";

enum CourseLandingPageSourceValues {
  DiscoveryPage = 'discovery_page',
  CourseLibrary = 'course_library',
  Direct = 'direct',
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { user } = useUserStore();
  const { fetchUserInfo, userInfo } = useAccount();
  const { course, setCourseId } = useCourseState();
  const { dispatchTrackingEvent } = useTracking();

  if (!course.value && to.params.courseId) {
    await setCourseId(to.params.courseId as string);
  }

  if (!course.value) {
    return;
  }

  await fetchUserInfo();

  const pathToTrackingValueMap: Record<string, CourseLandingPageSourceValues | null> = {
    '/dashboard': CourseLandingPageSourceValues.DiscoveryPage,
    '/courses': CourseLandingPageSourceValues.CourseLibrary,
    [to.path]: CourseLandingPageSourceValues.Direct,
  };

  const trackingValue = pathToTrackingValueMap[from.path] || null;
  if (!trackingValue) {
    return;
  }

  dispatchTrackingEvent(PageEvents.CourseLandingPageViewed, {
    'user': {
      'plan_type': user.value?.subscription?.type,
      'specialty': userInfo.value?.specialty,
    },
    'course_id': course.value.id,
    'landing_page_source': trackingValue,
  });
});